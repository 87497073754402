import React, { useEffect, useState } from "react";
import {
  useIonViewWillEnter,
  useIonToast,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonTextarea,
  IonToast,
  IonItemDivider,
  IonItemGroup,
  IonItemSliding,
  IonCard,
  IonCardContent,
  IonGrid,
  IonIcon,
  IonInput,
} from "@ionic/react";
import { ACTION_TYPES } from "../../redux/user/orders/actions";
import { DATA_ACTION_TYPES } from "../../redux/sessions/action";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import AuthHeader from "../../components/AuthHeader";
import { isDesktop, isMobile } from "react-device-detect";
import { RouteComponentProps, withRouter } from "react-router";

import "./Orders.css";
import { trashOutline, checkmarkCircleOutline } from "ionicons/icons";
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";

interface OwnProps extends RouteComponentProps {}
interface DispatchProps {}

interface OrderReviewsProps extends OwnProps, DispatchProps {
  setMenuAction: Function;
  setReviewAction: Function;
  isReviewAddedSuccess: boolean;
  isReviewAddedError: boolean;
}
const OrderReviews: React.FC<OrderReviewsProps> = ({
  history,
  setMenuAction,
  setReviewAction,
  isReviewAddedSuccess,
  isReviewAddedError,
}) => {
  const [selectedHistoryForReviews, setSelectedHistoryForReviews] = useState({
    orderId: "",
    review: "",
    rating: "3",
  });
  const [foodRating, setfoodRating] = useState(0);
  const [messageToast] = useIonToast();

  const presentToast = (
    position: "top" | "middle" | "bottom",
    message: any
  ) => {
    messageToast({
      message: message,
      duration: 3000,
      position: position,
    });
  };
  useIonViewWillEnter(() => {
    setMenuAction(true);
    setSelectedHistoryForReviews(
      JSON.parse(localStorage.getItem("selectedOrderForReviews")!)
    );
  });

  const handleChange = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    setSelectedHistoryForReviews((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (isReviewAddedSuccess) {
      presentToast("top", "Reviews added successfully.");
      history.push("/orderHistory", { direction: "none" });
    }
  }, [isReviewAddedSuccess]);

  const setReviews = () => {
    if (
      !selectedHistoryForReviews.orderId ||
      !selectedHistoryForReviews.review ||
      !foodRating
    ) {
      return;
    }
    let reviews = {
      orderId: selectedHistoryForReviews.orderId,
      review: selectedHistoryForReviews.review,
      rating: foodRating,
    };
    setReviewAction(reviews);
  };

  return (
    <IonPage>
      <AuthHeader></AuthHeader>
      <IonContent className="ion-padding">
        {isDesktop && (
          <>
            <div className="listing-block">
              <IonLabel className="pagename">
                <h2>Reviews</h2>
              </IonLabel>

              <IonCard className="display-card cart-view review-view">
                <IonCardContent>
                  <IonItem lines="none">
                    <IonGrid>
                      <IonRow>
                        <IonCol size="12" size-md="12">
                          <strong>Order ID:</strong>{" "}
                          {selectedHistoryForReviews.orderId}
                        </IonCol>
                        <IonCol
                          size="12"
                          size-md="12"
                          className="product-detail-block"
                        >
                          {/* &#11088; &#11088; &#11088; &#11088; &#11088; */}

                          <Rater
                            total={5}
                            rating={foodRating}
                            onRate={(rate) => setfoodRating(rate.rating)}
                          ></Rater>
                        </IonCol>
                        <IonCol
                          size="12"
                          size-md="12"
                          className="product-detail-block"
                        >
                          <IonItem>
                            <IonTextarea
                              name="review"
                              spellCheck={false}
                              autocapitalize="off"
                              rows={6}
                              autoGrow={true}
                              onIonChange={(e) => handleChange(e)}
                              placeholder="Share your reviews for the meal"
                              required
                            ></IonTextarea>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                </IonCardContent>
              </IonCard>

              <IonButton onClick={() => setReviews()}>
                <IonIcon src={checkmarkCircleOutline} /> Submit Reviews
              </IonButton>
            </div>
          </>
        )}

        {isMobile && (
          <>
            <div className="listing-block">
              <IonLabel className="pagename">
                <h2>Reviews</h2>
              </IonLabel>

              <IonCard className="display-card cart-view review-view mob-card-view">
                <IonCardContent>
                  <IonItem lines="none">
                    <IonGrid>
                      <IonRow>
                        <IonCol size="12" size-md="12">
                          <strong>Order ID:</strong>{" "}
                          {selectedHistoryForReviews.orderId}
                        </IonCol>
                        <IonCol
                          size="12"
                          size-md="12"
                          className="product-detail-block"
                        >
                          {/* &#11088; &#11088; &#11088; &#11088; &#11088; */}
                          <Rater
                            total={5}
                            rating={foodRating}
                            onRate={(rate) => setfoodRating(rate.rating)}
                          ></Rater>
                        </IonCol>
                        <IonCol
                          size="12"
                          size-md="12"
                          className="product-detail-block"
                        >
                          <IonItem>
                            <IonTextarea
                              name="review"
                              spellCheck={false}
                              autocapitalize="off"
                              rows={6}
                              autoGrow={true}
                              onIonChange={(e) => handleChange(e)}
                              placeholder="Share your reviews for the meal"
                              required
                            ></IonTextarea>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                </IonCardContent>
              </IonCard>

              <IonButton onClick={() => setReviews()}>
                <IonIcon src={checkmarkCircleOutline} /> Submit Reviews
              </IonButton>
            </div>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  isReviewAddedSuccess: state.orders.isReviewAddedSuccess,
  isReviewAddedError: state.orders.isReviewAddedSuccess,
});

function mapDispatchToProps(dispatch: any) {
  return {
    setMenuAction: (menuEnabled: boolean) =>
      dispatch({
        type: DATA_ACTION_TYPES.SET_MENU_ENABLED,
        payload: menuEnabled,
      }),
    setReviewAction: (reviews: any) =>
      dispatch({
        type: ACTION_TYPES.ADD_REVIEWS_REQUEST,
        payload: reviews,
      }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OrderReviews));

import axios from 'axios';
import axiosInstance from '../../components/config/axiosInstance';
export class AuthAPI {
  fetchregister = (data) => {
    return axiosInstance
      .post('/api/v1/auth/register', data)
      .then((response) => {
        localStorage.setItem('registeredMobile',JSON.stringify(response.data.mobileNo));
        localStorage.setItem('isRegister','1');

        return response.data;
      }).catch((err) => {
        return err;

      })
  }


  fetchlogin = (data) => {
    return axiosInstance
    .post('/api/v1/auth/login', data)
    .then((response) => {
      return response.data;
    }).catch((err) => {
      return err;

    })
  }

    verifyOtp = (data) => {
    return axiosInstance
    .post('/api/v1/auth/otpVerification', data)
    .then((response) => {
      return response.data;
    }).catch((err) => {
      return err;

    })
  }




    forgotPassword = (data) => {
      localStorage.setItem('registeredMobile',JSON.stringify(data.mobileNo));
    return axiosInstance
    .post('/api/v1/auth/forgotPasswordOTP', data)
    .then((response) => {
      return response.data;
    }).catch((err) => {
      return err;

    })
  }




    resetPassword = (data) => {
    return axiosInstance
    .post('/api/v1/auth/registerForgotPassword', data)
    .then((response) => {
      return response.data;
    }).catch((err) => {
      return err;

    })
  }
}
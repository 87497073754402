import Auth  from './constants'
export const ACTION_TYPES = {
    // Define Action types
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_REQUEST_SUCCESS: "LOGIN_REQUEST_SUCCESS",
    LOGIN_REQUEST_ERROR: "LOGIN_REQUEST_ERROR",

    REGISTER_REQUEST: "REGISTER_REQUEST",
    REGISTER_REQUEST_SUCCESS: "REGISTER_REQUEST_SUCCESS",
    REGISTER_REQUEST_ERROR: "REGISTER_REQUEST_ERROR",



    GETALL_REQUEST: "GETALL_REQUEST",
    GETALL_REQUEST_SUCCESS: "GETALL_REQUEST_SUCCESS",
    GETALL_REQUEST_ERROR: "GETALL_REQUEST_ERROR",

    FORGOT_PASSWORD_REQUEST:"FORGOT_PASSWORD_REQUEST",
    FORGOT_PASSWORD_REQUEST_SUCCESS:"FORGOT_PASSWORD_REQUEST_SUCCESS",
    FORGOT_PASSWORD_REQUEST_ERROR:"FORGOT_PASSWORD_REQUEST_ERROR",


    OTP_VERIFIED_REQUEST:"OTP_VERIFIED_REQUEST",
    OTP_VERIFIED_REQUEST_SUCCESS:"OTP_VERIFIED_REQUEST_SUCCESS",
    OTP_VERIFIED_REQUEST_ERROR:"OTP_VERIFIED_REQUEST_ERROR",


    RESET_PASSWORD_REQUEST:"RESET_PASSWORD_REQUEST",
    RESET_PASSWORD_REQUEST_SUCCESS:"RESET_PASSWORD_REQUEST_SUCCESS",
    RESET_PASSWORD_REQUEST_ERROR:"RESET_PASSWORD_REQUEST_ERROR",
  };

/*Register Action*/
export const fetchRegisterRequest = (userData) => ({
    type: ACTION_TYPES.REGISTER_REQUEST,
    payload: userData,
});

export const fetchRegisterSuccess = (userData) => ({
    type: ACTION_TYPES.REGISTER_REQUEST_SUCCESS,
    payload: userData,
});


export const fetchRegisterError = (userData) => ({
    type: ACTION_TYPES.REGISTER_REQUEST_ERROR,
    payload: userData,
});

/*Login Action*/
export const fetchLoginRequest = (userData) => ({
    type: ACTION_TYPES.LOGIN_REQUEST,
    payload: userData,
});

export const fetchLoginSuccess = (userData) => ({
    type: ACTION_TYPES.LOGIN_REQUEST_SUCCESS,
    payload: userData,
});


export const fetchLoginError = (userData) => ({
    type: ACTION_TYPES.LOGIN_REQUEST_ERROR,
    payload: userData,
});

/*Verify OTP Action*/
export const verifyOtpRequest = (otpData) => ({
    type: ACTION_TYPES.OTP_VERIFIED_REQUEST,
    payload: otpData,
});

export const verifyOtpSuccess = () => ({
    type: ACTION_TYPES.OTP_VERIFIED_REQUEST_SUCCESS
});


export const verifyOtpError = () => ({
    type: ACTION_TYPES.OTP_VERIFIED_REQUEST_ERROR
});


/*ForgotPass Action*/
export const forgotPasswordRequest = (data) => ({
    type: ACTION_TYPES.FORGOT_PASSWORD_REQUEST,
    payload: data,
});

export const forgotPasswordSuccess = () => ({
    type: ACTION_TYPES.FORGOT_PASSWORD_REQUEST_SUCCESS
});


export const forgotPasswordError = () => ({
    type: ACTION_TYPES.FORGOT_PASSWORD_REQUEST_ERROR
});


/*Reset Password Action*/
export const resetPassworddRequest = () => ({
    type: ACTION_TYPES.RESET_PASSWORD_REQUEST
});

export const resetPassworddSuccess = () => ({
    type: ACTION_TYPES.RESET_PASSWORD_REQUEST_SUCCESS
});


export const resetPassworddError = () => ({
    type: ACTION_TYPES.RESET_PASSWORD_REQUEST_ERROR
});



import { combineReducers } from 'redux';

import { authReducer } from '../redux/auth/reducer';
import { ordersReducer } from '../redux/user/orders/reducer';

import { sessionsReducer } from '../redux/sessions/reducer';
import { counterheadReducer } from './user/counterhead/reducer'



// right now we have only 1 reducer, but lets use this format of combineReducers so you can add more later if you need to.
const rootReducer = combineReducers({
  auth: authReducer,
  orders: ordersReducer,
  data: sessionsReducer,
  counterheadData: counterheadReducer
});

export default rootReducer;
/*------- THIS IS THE MAIN SAGA COMPONENT -------*/

import { all } from "redux-saga/effects";

/* IMPORT ALL SAGA WATCHERS */
import watchAuth from "./auth/sagas";
import watchUserOrders from "./user/orders/sagas";
import watchCounterhead from "./user/counterhead/sagas";





/* CREATE THE ROOT SAGA */
function* rootSaga() {
  yield all([
    watchAuth(),
    watchUserOrders(),
    watchCounterhead()]);
}

export default rootSaga;
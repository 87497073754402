import { IonFooter, IonToolbar, IonTitle } from "@ionic/react";

interface ContainerProps {}

const CopyRight: React.FC<ContainerProps> = () => {
  return (
    <p className="text-center">
      <small>© 2023 Venture Professional. All Right Reserved</small>
    </p>
  );
};

export default CopyRight;

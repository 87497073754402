import axios from 'axios';
import axiosInstance from '../../../components/config/axiosInstance';

export class OrdersAPI {

  


  getMenus = () => {

    return axiosInstance
    .get('/api/v1/order/getMenu')
    .then((response) => {
      return response.data;
    }).catch((err) => {
      return err;

    })
  }


  getOrders = () => {
    return axiosInstance
    .get('/api/v1/order/getActiveOrdersByUserId')
    .then((response) => {
      return response.data;
    }).catch((err) => {
      return err;

    })
  }

  getOrderHistory = (filters) => {

    return axiosInstance
    .post('/api/v1/order/orderHistory',filters)
    .then((response) => {
      return response.data;
    }).catch((err) => {
      return err;

    })
  }

  addToCart = (cartItems) => {

    return axiosInstance
    .post('/api/v1/order/addCart',cartItems)
    .then((response) => {
      return response.data;
    }).catch((err) => {
      return err;

    })
  }



  updateCart = (updatedcartItems) => {

    return axiosInstance
    .post('/api/v1/order/updateCart',updatedcartItems)
    .then((response) => {
      return response.data;
    }).catch((err) => {
      return err;

    })
  }

  deleteCart = (updatedcartItems) => {

    return axiosInstance
    .post('/api/v1/order/deleteCart',updatedcartItems)
    .then((response) => {

      return response.data;
    }).catch((err) => {
      return err;

    })
  }

  getCart = (orderDetails) => {

    return axiosInstance
    .get('/api/v1/order/getCart')
    .then((response) => {
      return response.data;
    }).catch((err) => {
      return err;

    })
  }

  placeOrder = (orderDetails) => {

    return axiosInstance
    .post('/api/v1/order/placeOrderPaytm',orderDetails)
    .then((response) => {

      return response.data;
    }).catch((err) => {
      return err;

    })
  }


  addReviews = (reviews) => {
    return axiosInstance
    .post('/api/v1/order/addReview',reviews)
    .then((response) => {
      return response.data;
    }).catch((err) => {
      return err;

    })
  }

 updateSubMenu = (subMenu) => {
    return axiosInstance
    .post('/api/v1/admin/updateSubCategory',subMenu)
    .then((response) => {
      return response.data;
    }).catch((err) => {
      return err;

    })
  }


  updateMenu = (menu) => {
    return axiosInstance
    .post('/api/v1/admin/updateMenu',menu)
    .then((response) => {
      return response.data;
    }).catch((err) => {
      return err;

    })
  }


  payOrderOnline = (details) => {
    return axiosInstance
    .post('/api/v1/order/paymentGatewaySuccess',details)
    .then((response) => {
      return response.data;
    }).catch((err) => {
      return err;

    })
  }

  rePayCashRequest= (details) => {
      return axiosInstance
      .post('/api/v1/order/updatePaymentModeToCash',details)
      .then((response) => {
        return response.data;
      }).catch((err) => {
        return err;

      })
    }

    rePayOnlinePaytmRequest= (details) => {
      return axiosInstance
      .post('/api/v1/order/makePaymentForOrderPaytm',details)
      .then((response) => {
        return response.data;
      }).catch((err) => {
        return err;

      })
    }
    

    payOrderOnlinePaytm = (details) => {
    return axiosInstance
    .post('/api/v1/order/paymentGatewaySuccessPaytm',details)
    .then((response) => {
      return response.data;
    }).catch((err) => {
      return err;

    })
  }

  payOrderOnlineSodexo = (details) => {
    return axiosInstance
    .post('/api/v1/order/paymentGatewaySuccessSodaxo',details)
    .then((response) => {
      return response.data;
    }).catch((err) => {
      return err;
    })
  }


  
  orderCancel = (orderId) => {
    return axiosInstance
    .post('/api/v1/order/cancelOrder',orderId)
    .then((response) => {
      return response.data;
    }).catch((err) => {
      return err;
    })
  }

  
}
import { call, put, takeEvery, takeLatest, all } from 'redux-saga/effects';
import Auth  from './constants'
import { AuthAPI } from './AuthAPI';
import {
    fetchRegisterSuccess,
    fetchRegisterError,
    ACTION_TYPES,

    fetchLoginSuccess,
    fetchLoginError,

    verifyOtpSuccess,
    verifyOtpError,
    forgotPasswordSuccess,
    forgotPasswordError,
    resetPassworddSuccess,
    resetPassworddError
  } from './actions';

const authApi = new AuthAPI;


function* fetchregisterFromApi(action) {

    const data = action.payload;
    try {
      // call the api
      const response = yield call(authApi.fetchregister, action.payload)
      if(response.status == 100) {
        // call the success action with data
        yield put(fetchRegisterSuccess(response));
      } else {
        yield put(fetchRegisterError(response));
      }
    } catch (e) {
        // call the error action with data
        yield put(fetchRegisterError(e));
    }
}


function* fetchloginFromApi(action) {

  const data = action.payload;
  try {
      // call the api
      const response = yield call(authApi.fetchlogin, action.payload)
    
      if(response.status == 100) {
        // call the success action with data
        yield put(fetchLoginSuccess(response));
         
          localStorage.setItem('token',JSON.stringify(response.token));
          localStorage.setItem('isLoggedIn',JSON.stringify(1));

          if(response.role != undefined && response.role == 1 || !response.role ){
            response['role'] = 'User';
          }
          else if(response.role != undefined && response.role == 2) {
            response['role'] = 'CounterHead';
          }  
          else if(response.role != undefined && response.role == 4) {
            //Handle this case...
            response['role'] = 'CounterLiveStatus';
          }
          else if(response.role != undefined && response.role == 3) {
            //Handle this case...
            response['role'] = 'Admin';

          }

          // if(response.role != undefined || response.mobile == '8655012457' || response.mobile == '7208068019') {
          //   response['role'] = 'CounterHead';
          // } else {
          //   response['role'] = 'User';
          // }
          localStorage.setItem('userDetails',JSON.stringify(response));
          localStorage.setItem('token',JSON.stringify(response.token));

      } else {
        yield put(fetchLoginError(response));
      }

      
  } catch (e) {

      // call the error action with data
      yield put(fetchLoginError(e));
  }

}


function* verifyOtpFromApi(action) {
    const data = action.payload;
    try {
      // call the api
      const response = yield call(authApi.verifyOtp, action.payload)
        yield put(verifyOtpSuccess(response));

      if(response.status == 100) {
        // call the success action with data
        yield put(verifyOtpSuccess(response));
      } else {
        yield put(verifyOtpError(response));
      }
    } catch (e) {
        // call the error action with data
        yield put(verifyOtpError(e));
    }
}



function* forgotPasswordFromApi(action) {
    const data = action.payload;
    try {
      // call the api
      const response = yield call(authApi.forgotPassword, action.payload);
        yield put(forgotPasswordSuccess(response));

      if(response.status == 102) {
        // call the success action with data
        yield put(forgotPasswordSuccess(response));
      } else {
        yield put(forgotPasswordError(response));
      }
    } catch (e) {
        // call the error action with data
        yield put(forgotPasswordError(e));
    }
}



function* resetPasswordFromApi(action) {
    const data = action.payload;
    try {
      // call the api
      const response = yield call(authApi.resetPassword, action.payload)
      yield put(resetPassworddSuccess(response));

      if(response.status == 100) {
        // call the success action with data
        yield put(resetPassworddSuccess(response));
      } else {
         yield put(resetPassworddError(response));
      }
    } catch (e) {
        // call the error action with data
        yield put(resetPassworddError(response));
    }
}


export default function* watchAuth() {
  yield takeEvery(ACTION_TYPES.REGISTER_REQUEST, fetchregisterFromApi);
  yield takeEvery(ACTION_TYPES.LOGIN_REQUEST, fetchloginFromApi);
  yield takeEvery(ACTION_TYPES.OTP_VERIFIED_REQUEST, verifyOtpFromApi);
  yield takeEvery(ACTION_TYPES.FORGOT_PASSWORD_REQUEST, forgotPasswordFromApi);
  yield takeEvery(ACTION_TYPES.RESET_PASSWORD_REQUEST, resetPasswordFromApi);

}

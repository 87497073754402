import React, { useEffect, useState } from "react";
import {
  useIonViewWillEnter,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonTextarea,
  IonToast,
  IonItemDivider,
  IonItemGroup,
  IonItemSliding,
  IonCard,
  IonCardContent,
  IonGrid,
  IonIcon,
  IonInput,
} from "@ionic/react";
import { ACTION_TYPES } from "../../redux/user/orders/actions";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import AuthHeader from "../../components/AuthHeader";
import { isDesktop, isMobile } from "react-device-detect";
import { trashOutline, checkmarkCircleOutline } from "ionicons/icons";

interface OwnProps {}

interface DispatchProps {}

interface MenuProps {
  menuName: "";
  subMenuDetails: [
    { subCategoryName: ""; isIncludedInPackage: false; smdId: 0 }
  ];
  availableQty: "";
  price: 0;
  mdId: 0;
  image_link: "";
  menuId: "";
  status: 1;
  isIncludedInPackage: null;
  quantity: 0;
}
interface AdminDashboardProps extends OwnProps, DispatchProps {
  getMenuListAction: Function;
  isMenuSuccess: boolean;
  isMenuError: boolean;
  updateMenuAction: Function;
  isMenuUpdatedSuccess: boolean;
  isMenuUpdatedError: boolean;
  menuData: Array<MenuProps>;
}
const AdminDashboard: React.FC<AdminDashboardProps> = ({
  getMenuListAction,
  isMenuSuccess,
  isMenuError,
  updateMenuAction,
  isMenuUpdatedSuccess,
  isMenuUpdatedError,
  menuData,
}) => {
  // useEffect(() => {
  //   getMenuListAction();
  // }, []);

  return (
    <IonPage>
      <AuthHeader></AuthHeader>
      <IonContent className="ion-padding">
        {/* {isDesktop && */}
        <>
          <div className="listing-block">
            <IonLabel className="pagename">
              <h2>
                Dashboard <span className="role-badge">Admin</span>
              </h2>
            </IonLabel>

            <IonCard className="table-layout">
              <IonCardContent></IonCardContent>
            </IonCard>
          </div>
        </>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  menuData: state.orders.menuData,
  isMenuSuccess: state.orders.isMenuSuccess,
  isMenuError: state.orders.isMenuUpdatedSuccess,
  isMenuUpdatedSuccess: state.orders.isMenuUpdatedSuccess,
  isMenuUpdatedError: state.orders.isMenuUpdatedError,
});

function mapDispatchToProps(dispatch: any) {
  return {
    getMenuListAction: () =>
      dispatch({
        type: ACTION_TYPES.GET_MENU_REQUEST,
      }),
    updateMenuAction: (subMenu: boolean) =>
      dispatch({
        type: ACTION_TYPES.UPDATE_MENU_REQUEST,
        payload: subMenu,
      }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);

import { DATA_ACTION_TYPES } from "./action";

const initialState = {
  menuEnabled: false
} 

export const sessionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_ACTION_TYPES.SET_MENU_ENABLED: {
        return { ...state, menuEnabled: action.payload };
      }
      default:
      return state
    }
}
import React, { useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonRow, IonCol, IonButton, IonList, IonItem, IonLabel, IonText, IonTextarea, IonToast } from '@ionic/react';
import './Login.scss';

interface OwnProps { }

interface DispatchProps { }

interface PrivacyPolicyProps extends OwnProps, DispatchProps { }

const PrivacyPolicy: React.FC<PrivacyPolicyProps> = () => {

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Privacy Policy</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>

      </IonContent>


    </IonPage>
  );
};
export default PrivacyPolicy;
import React, { useEffect, useState } from "react";
import {
  useIonViewWillEnter,
  useIonToast,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonTextarea,
  IonToast,
  IonItemDivider,
  IonItemGroup,
  IonItemSliding,
  IonCard,
  IonCardContent,
  IonGrid,
  IonIcon,
  IonInput,
} from "@ionic/react";
import { ACTION_TYPES } from "../../redux/user/orders/actions";
import { DATA_ACTION_TYPES } from "../../redux/sessions/action";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import AuthHeader from "../../components/AuthHeader";
import { isDesktop, isMobile } from "react-device-detect";
import { RouteComponentProps, withRouter } from "react-router";

import "./Orders.css";
import { trashOutline, checkmarkCircleOutline } from "ionicons/icons";
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";
import moment from "moment";

interface OwnProps extends RouteComponentProps {}
interface DispatchProps {}

interface OrderDetailsProps extends OwnProps, DispatchProps {
  setMenuAction: Function;
}
const OrderDetails: React.FC<OrderDetailsProps> = ({ setMenuAction }) => {
  const [orderSummary, setOrderSummary] = useState({
    orderId: null,
    createdBy: "",
    paymentMode: null,
    createdOn: "",
    orderMenuResponse: [
      {
        quantity: 0,
        menuDetails: { image_link: "", menuName: "", price: 0 },
        subMenuDetails: { subCategoryName: "", price: 0 },
      },
    ],
  });
  const [productTotal, setproductTotal] = useState(0);

  // let showSummary = {};

  useIonViewWillEnter(() => {
    setMenuAction(true);

    if (localStorage.getItem("selectedOrderSummary") != null) {
      // showSummary = JSON.parse(localStorage.getItem("selectedOrderSummary")!);
      setOrderSummary(
        JSON.parse(localStorage.getItem("selectedOrderSummary")!)
      );
    }
  });

  useEffect(() => {
    let productTotalCal = 0;
    orderSummary.orderMenuResponse.forEach((elm: any) => {
      if (elm.menuDetails != null) {
        productTotalCal += elm.menuDetails.price * elm.quantity;
      } else {
        productTotalCal += elm.subMenuDetails.price * elm.quantity;
      }
    });

    setproductTotal(productTotalCal);
  }, [orderSummary]);

  return (
    <IonPage>
      <AuthHeader></AuthHeader>
      <IonContent className="ion-padding">
        {/* {isDesktop && ( */}
        <>
          <div className="listing-block">
            {orderSummary.orderId != null && (
              <>
                <IonLabel className="pagename">
                  <h2>Order Summary</h2>
                </IonLabel>

                <IonList>
                  <div className="order-card">
                    <div className="order-description">
                      <h5>Order ID: {orderSummary.orderId}</h5>

                      <hr />
                      {orderSummary.orderMenuResponse.map(
                        (orderMenuResp, subIndex) => {
                          return (
                            <>
                              <p>
                                <strong>
                                  {orderMenuResp.menuDetails?.menuName
                                    ? orderMenuResp.menuDetails?.menuName
                                    : orderMenuResp.subMenuDetails
                                        ?.subCategoryName}
                                </strong>{" "}
                                <br />
                                Quantity:
                                {orderMenuResp.quantity}
                              </p>
                              <h5>More details</h5>
                              <table>
                                <tbody>
                                  <tr>
                                    <td>Price:</td>
                                    <td>
                                      INR.{" "}
                                      {orderMenuResp.menuDetails?.price
                                        ? orderMenuResp.menuDetails?.menuName
                                        : orderMenuResp.subMenuDetails?.price}
                                    </td>
                                  </tr>
                                  {/* <tr>
                                      <td>Taxes:</td>
                                      <td>INR. </td>
                                    </tr> */}
                                  <tr>
                                    <td>Total:</td>
                                    <td>
                                      <strong>
                                        INR.{" "}
                                        {orderMenuResp.menuDetails != null
                                          ? orderMenuResp.menuDetails.price *
                                            orderMenuResp.quantity
                                          : orderMenuResp.subMenuDetails.price *
                                            orderMenuResp.quantity}
                                      </strong>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </>
                          );
                        }
                      )}

                      <hr />

                      <h5>Summary details</h5>
                      <table>
                        <tbody>
                          <tr>
                            <td>Total amount:</td>
                            <td>
                              <strong>INR. {productTotal} </strong>
                            </td>
                          </tr>
                          <tr>
                            <td>Ordered by:</td>
                            <td>
                              {/* Pratiksha{" "} */}
                              {/* <small> */}
                              VP {orderSummary.createdBy}
                              {/* </small> */}
                            </td>
                          </tr>
                          <tr>
                            <td>Placed on:</td>
                            <td>
                              {moment(orderSummary.createdOn).format(
                                "DD/MM/YYYY,h:mm:ss a"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Paid via:</td>
                            <td>
                              {orderSummary.paymentMode != null &&
                              orderSummary.paymentMode == 1
                                ? "COD"
                                : orderSummary.paymentMode != null &&
                                  orderSummary.paymentMode == 1 &&
                                  "ONLINE"}{" "}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </IonList>
              </>
            )}

            {/* {Object.keys(showSummary).length > 0 && (
                <IonCard className="display-card cart-view review-view">
                  <IonCardContent>
                    <IonItem lines="none"></IonItem>
                  </IonCardContent>
                </IonCard>
              )} */}
          </div>
        </>

        {/* {isMobile && (
          <>
            <div className="listing-block">
              <IonLabel className="pagename">
                <h2>Order Summary</h2>
              </IonLabel>

              <IonCard className="display-card cart-view review-view mob-card-view">
                <IonCardContent>
                  <IonItem lines="none"></IonItem>
                </IonCardContent>
              </IonCard>
            </div>
          </>
        )} */}
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({});

function mapDispatchToProps(dispatch: any) {
  return {
    setMenuAction: (menuEnabled: boolean) =>
      dispatch({
        type: DATA_ACTION_TYPES.SET_MENU_ENABLED,
        payload: menuEnabled,
      }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OrderDetails));

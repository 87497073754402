import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useState } from "react";
import { Bar } from "react-chartjs-2";
// import faker from "faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface ContainerProps {
  menuWiseReportData: [];
}

interface MenuType {
  menuName: "";
  totalPrice: 0;
}

const BarGraph: React.FC<ContainerProps> = (props) => {

  const [isOpen, setIsOpen] = useState(false);

  let labels = new Array();
  let dataSets = new Array();

  props.menuWiseReportData.forEach((element: MenuType) => {
    labels.push(element.menuName);
    dataSets.push(element.totalPrice);
  });

  const data = {
    labels,
    datasets: [
      {
        label: "Menu",
        data: dataSets,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Chart.js Bar Chart",
      },
    },
  };

  return <div>{<Bar options={options} data={data} />}</div>;
};

export default BarGraph;

import React, { useEffect, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonTextarea,
  IonToast,
  IonInput,
  IonGrid,
  IonIcon,
  useIonViewWillEnter,
} from "@ionic/react";
import "./Login.scss";
import AuthHeader from "../components/AuthHeader";
import { useFormik } from "formik";
import { isDesktop, isMobile } from "react-device-detect";
import { sendOutline } from "ionicons/icons";
import { DATA_ACTION_TYPES } from "../redux/sessions/action";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";

interface OwnProps {}

interface DispatchProps {}

interface SettingsProps extends OwnProps, DispatchProps {
  setMenuAction: Function;
}

const Settings: React.FC<SettingsProps> = ({ setMenuAction }) => {
  const [userDetails, setuserDetails] = useState({
    firstname: "",
    lastname: "",
  });
  useEffect(() => {
    if (localStorage.getItem("userDetails") != null) {
      let userDetails = JSON.parse(localStorage.getItem("userDetails")!);
      setuserDetails(userDetails);
    }
  }, []);

  useIonViewWillEnter(() => {
    setMenuAction(true);
  });

  const validate = (values: any) => {
    const errors: any = {};
    if (!values.firstname) {
      errors.firstname = "Required";
    } else if (values.firstname.length > 32) {
      errors.firstname = "Must be 32 characters or less";
    }

    if (!values.lastname) {
      errors.lastname = "Required";
    } else if (values.lastname.length > 32) {
      errors.lastname = "Must be 32 characters or less";
    }

    if (!values.mobile) {
      errors.mobile = "Required";
    } else if (values.mobile.toString().length > 10) {
      errors.mobile = "Must be 10 digit mobile number";
    }

    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length > 60) {
      errors.password = "Must be 60 characters or less";
    }
    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      firstname: userDetails.firstname,
      lastname: userDetails.firstname,
      email: "",
      mobile: "",
      state: "",
      city: "",
      empOrgName: "",
      empDept: "",
      empPosition: "",
    },
    validate,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  return (
    <IonPage>
      <AuthHeader></AuthHeader>
      <IonContent className="ion-padding">
        {isDesktop && (
          <>
            <div className="listing-block ion-padding">
              <IonLabel className="pagename">
                <h2>Settings</h2>
              </IonLabel>
            </div>

            <form className="no-form" onSubmit={formik.handleSubmit}>
              <IonList>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <h4>Basic details</h4>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol size="6" size-md="6">
                      <IonItem fill="outline">
                        <IonLabel position="floating">First name</IonLabel>
                        <IonInput
                          id="firstname"
                          name="firstname"
                          type="text"
                          onIonChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.firstname}
                          spellCheck={false}
                          autocapitalize="off"
                          required
                          maxlength={32}
                        ></IonInput>
                      </IonItem>

                      {formik.touched.firstname && formik.errors.firstname ? (
                        <IonText color="danger">
                          {formik.errors.firstname}
                        </IonText>
                      ) : null}
                    </IonCol>

                    <IonCol size="6" size-md="6">
                      <IonItem fill="outline">
                        <IonLabel position="floating">Last name</IonLabel>
                        <IonInput
                          id="lastname"
                          name="lastname"
                          type="text"
                          onIonChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.lastname}
                          spellCheck={false}
                          autocapitalize="off"
                          required
                          maxlength={32}
                        ></IonInput>
                      </IonItem>

                      {formik.touched.lastname && formik.errors.lastname ? (
                        <IonText color="danger">
                          {formik.errors.lastname}
                        </IonText>
                      ) : null}
                    </IonCol>

                    <IonCol size="12" size-md="12">
                      <IonItem fill="outline">
                        <IonLabel position="floating">Email</IonLabel>
                        <IonInput
                          id="email"
                          name="email"
                          type="email"
                          onIonChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          spellCheck={false}
                          autocapitalize="off"
                          required
                        ></IonInput>
                      </IonItem>

                      {formik.touched.email && formik.errors.email ? (
                        <IonText color="danger">{formik.errors.email}</IonText>
                      ) : null}
                    </IonCol>

                    <IonCol size="12" size-md="12">
                      <IonItem fill="outline">
                        <IonLabel position="floating">Mobile number</IonLabel>
                        <IonInput
                          id="mobile"
                          name="mobile"
                          type="number"
                          onIonChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.mobile}
                          spellCheck={false}
                          autocapitalize="off"
                          required
                          maxlength={10}
                        ></IonInput>
                      </IonItem>

                      {formik.touched.mobile && formik.errors.mobile ? (
                        <IonText color="danger">{formik.errors.mobile}</IonText>
                      ) : null}
                    </IonCol>

                    <IonCol size="6" size-md="6">
                      <IonItem fill="outline">
                        <IonLabel position="floating">State</IonLabel>
                        <IonInput
                          id="state"
                          name="state"
                          type="text"
                          onIonChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.state}
                          spellCheck={false}
                          autocapitalize="off"
                          required
                          maxlength={60}
                        ></IonInput>
                      </IonItem>

                      {/* {formik.touched.state && formik.errors.state ? (
                    <IonText color="danger">{formik.errors.state}</IonText>
                  ) : null} */}
                    </IonCol>

                    <IonCol size="6" size-md="6">
                      <IonItem fill="outline">
                        <IonLabel position="floating">City</IonLabel>
                        <IonInput
                          id="state"
                          name="state"
                          type="text"
                          onIonChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.state}
                          spellCheck={false}
                          autocapitalize="off"
                          required
                          maxlength={60}
                        ></IonInput>
                      </IonItem>

                      {/* {formik.touched.state && formik.errors.state ? (
                    <IonText color="danger">{formik.errors.state}</IonText>
                  ) : null} */}
                    </IonCol>
                  </IonRow>
                </IonGrid>

                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <h4>Professional details</h4>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol size="12" size-md="12">
                      <IonItem fill="outline">
                        <IonLabel position="floating">
                          Organization name
                        </IonLabel>
                        <IonInput
                          id="empOrgName"
                          name="empOrgName"
                          type="text"
                          onIonChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.empOrgName}
                          spellCheck={false}
                          autocapitalize="off"
                          required
                          maxlength={60}
                        ></IonInput>
                      </IonItem>

                      {/* {formik.touched.empOrgName && formik.errors.empOrgName ? (
                    <IonText color="danger">{formik.errors.empOrgName}</IonText>
                    ) : null} */}
                    </IonCol>

                    <IonCol size="12" size-md="12">
                      <IonItem fill="outline">
                        <IonLabel position="floating">Position</IonLabel>
                        <IonInput
                          id="empPosition"
                          name="empPosition"
                          type="text"
                          onIonChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.empPosition}
                          spellCheck={false}
                          autocapitalize="off"
                          required
                          maxlength={60}
                        ></IonInput>
                      </IonItem>

                      {/* {formik.touched.empPosition && formik.errors.empPosition ? (
                    <IonText color="danger">{formik.errors.empPosition}</IonText>
                    ) : null} */}
                    </IonCol>

                    <IonCol size="12" size-md="12">
                      <IonItem fill="outline">
                        <IonLabel position="floating">Department</IonLabel>
                        <IonInput
                          id="empDept"
                          name="empDept"
                          type="text"
                          onIonChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.empDept}
                          spellCheck={false}
                          autocapitalize="off"
                          required
                          maxlength={60}
                        ></IonInput>
                      </IonItem>

                      {/* {formik.touched.empDept && formik.errors.empDept ? (
                    <IonText color="danger">{formik.errors.empDept}</IonText>
                    ) : null} */}
                    </IonCol>
                  </IonRow>
                </IonGrid>

                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonButton type="submit">
                        <IonIcon src={sendOutline} />
                        Update
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonList>
            </form>
          </>
        )}

        {isMobile && (
          <>
            <div className="listing-block">
              <IonLabel className="pagename">
                <h2>Settings</h2>
              </IonLabel>
            </div>

            <form className="no-form" onSubmit={formik.handleSubmit}>
              <IonList>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <h4>Basic details</h4>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol size="12" size-md="12">
                      <IonItem fill="outline">
                        <IonLabel position="floating">First name</IonLabel>
                        <IonInput
                          id="firstname"
                          name="firstname"
                          type="text"
                          onIonChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.firstname}
                          spellCheck={false}
                          autocapitalize="off"
                          required
                          maxlength={32}
                        ></IonInput>
                      </IonItem>

                      {formik.touched.firstname && formik.errors.firstname ? (
                        <IonText color="danger">
                          {formik.errors.firstname}
                        </IonText>
                      ) : null}
                    </IonCol>

                    <IonCol size="12" size-md="12">
                      <IonItem fill="outline">
                        <IonLabel position="floating">Last name</IonLabel>
                        <IonInput
                          id="lastname"
                          name="lastname"
                          type="text"
                          onIonChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.lastname}
                          spellCheck={false}
                          autocapitalize="off"
                          required
                          maxlength={32}
                        ></IonInput>
                      </IonItem>

                      {formik.touched.lastname && formik.errors.lastname ? (
                        <IonText color="danger">
                          {formik.errors.lastname}
                        </IonText>
                      ) : null}
                    </IonCol>

                    <IonCol size="12" size-md="12">
                      <IonItem fill="outline">
                        <IonLabel position="floating">Email</IonLabel>
                        <IonInput
                          id="email"
                          name="email"
                          type="email"
                          onIonChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          spellCheck={false}
                          autocapitalize="off"
                          required
                        ></IonInput>
                      </IonItem>

                      {formik.touched.email && formik.errors.email ? (
                        <IonText color="danger">{formik.errors.email}</IonText>
                      ) : null}
                    </IonCol>

                    <IonCol size="12" size-md="12">
                      <IonItem fill="outline">
                        <IonLabel position="floating">Mobile number</IonLabel>
                        <IonInput
                          id="mobile"
                          name="mobile"
                          type="number"
                          onIonChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.mobile}
                          spellCheck={false}
                          autocapitalize="off"
                          required
                          maxlength={10}
                        ></IonInput>
                      </IonItem>

                      {formik.touched.mobile && formik.errors.mobile ? (
                        <IonText color="danger">{formik.errors.mobile}</IonText>
                      ) : null}
                    </IonCol>

                    <IonCol size="12" size-md="12">
                      <IonItem fill="outline">
                        <IonLabel position="floating">State</IonLabel>
                        <IonInput
                          id="state"
                          name="state"
                          type="text"
                          onIonChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.state}
                          spellCheck={false}
                          autocapitalize="off"
                          required
                          maxlength={60}
                        ></IonInput>
                      </IonItem>

                      {/* {formik.touched.state && formik.errors.state ? (
                    <IonText color="danger">{formik.errors.state}</IonText>
                  ) : null} */}
                    </IonCol>

                    <IonCol size="12" size-md="12">
                      <IonItem fill="outline">
                        <IonLabel position="floating">City</IonLabel>
                        <IonInput
                          id="state"
                          name="state"
                          type="text"
                          onIonChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.state}
                          spellCheck={false}
                          autocapitalize="off"
                          required
                          maxlength={60}
                        ></IonInput>
                      </IonItem>

                      {/* {formik.touched.state && formik.errors.state ? (
                    <IonText color="danger">{formik.errors.state}</IonText>
                  ) : null} */}
                    </IonCol>
                  </IonRow>
                </IonGrid>

                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <h4>Professional details</h4>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol size="12" size-md="12">
                      <IonItem fill="outline">
                        <IonLabel position="floating">
                          Organization name
                        </IonLabel>
                        <IonInput
                          id="empOrgName"
                          name="empOrgName"
                          type="text"
                          onIonChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.empOrgName}
                          spellCheck={false}
                          autocapitalize="off"
                          required
                          maxlength={60}
                        ></IonInput>
                      </IonItem>

                      {/* {formik.touched.empOrgName && formik.errors.empOrgName ? (
                    <IonText color="danger">{formik.errors.empOrgName}</IonText>
                    ) : null} */}
                    </IonCol>

                    <IonCol size="12" size-md="12">
                      <IonItem fill="outline">
                        <IonLabel position="floating">Position</IonLabel>
                        <IonInput
                          id="empPosition"
                          name="empPosition"
                          type="text"
                          onIonChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.empPosition}
                          spellCheck={false}
                          autocapitalize="off"
                          required
                          maxlength={60}
                        ></IonInput>
                      </IonItem>

                      {/* {formik.touched.empPosition && formik.errors.empPosition ? (
                    <IonText color="danger">{formik.errors.empPosition}</IonText>
                    ) : null} */}
                    </IonCol>

                    <IonCol size="12" size-md="12">
                      <IonItem fill="outline">
                        <IonLabel position="floating">Department</IonLabel>
                        <IonInput
                          id="empDept"
                          name="empDept"
                          type="text"
                          onIonChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.empDept}
                          spellCheck={false}
                          autocapitalize="off"
                          required
                          maxlength={60}
                        ></IonInput>
                      </IonItem>

                      {/* {formik.touched.empDept && formik.errors.empDept ? (
                    <IonText color="danger">{formik.errors.empDept}</IonText>
                    ) : null} */}
                    </IonCol>
                  </IonRow>
                </IonGrid>

                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonButton type="submit">
                        <IonIcon src={sendOutline} />
                        Update
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonList>
            </form>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({});

function mapDispatchToProps(dispatch: any) {
  return {
    setMenuAction: (menuEnabled: boolean) =>
      dispatch({
        type: DATA_ACTION_TYPES.SET_MENU_ENABLED,
        payload: menuEnabled,
      }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);

import React, { useEffect, useState } from "react";
import {
  useIonViewWillEnter,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonTextarea,
  IonToast,
  IonItemDivider,
  IonItemGroup,
  IonItemSliding,
  IonCard,
  IonCardContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonBadge,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonModal,
  IonToggle,
  useIonToast,
} from "@ionic/react";
import { ACTION_TYPES, setCartCount } from "../../redux/user/orders/actions";
import { DATA_ACTION_TYPES } from "../../redux/sessions/action";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import AuthHeader from "../../components/AuthHeader";
import { isDesktop, isMobile } from "react-device-detect";
import {
  trashOutline,
  checkmarkCircleOutline,
  pencilOutline,
  informationCircle,
  map,
  personCircle,
} from "ionicons/icons";
import Rater from "react-rater";

interface OwnProps {}

interface DispatchProps {}
interface MenuProps {
  menuName: "";
  subMenuDetails: [
    { subCategoryName: ""; isIncludedInPackage: false; smdId: 0 }
  ];
  availableQty: "";
  price: 0;
  mdId: 0;
  image_link: "";
  menuId: "";
  status: 1;
  isIncludedInPackage: null;
  quantity: 0;
}

interface EditMenuProps extends OwnProps, DispatchProps {
  setMenuAction: Function;
  getMenuListAction: Function;
  isMenuSuccess: boolean;
  isMenuError: boolean;
  menuData: Array<MenuProps>;
  updateMenuAction: Function;
  isSubMenuSuccess: boolean;
  isSubMenuError: boolean;
  isMenuUpdatedSuccess: boolean;
  isMenuUpdatedError: boolean;
  resetUpdateAction: Function;
}
const EditMenu: React.FC<EditMenuProps> = ({
  setMenuAction,
  getMenuListAction,
  isMenuSuccess,
  isMenuError,
  menuData,
  updateMenuAction,
  isSubMenuSuccess,
  isSubMenuError,
  isMenuUpdatedSuccess,
  isMenuUpdatedError,
  resetUpdateAction,
}) => {
  const [messageToast] = useIonToast();

  const presentToast = (
    position: "top" | "middle" | "bottom",
    message: any
  ) => {
    messageToast({
      message: message,
      duration: 3000,
      position: position,
    });
  };

  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [selectedMenu, setSelectedMenu] = useState({
    menuName: "",
    subMenuDetails: [
      { subCategoryName: "", isIncludedInPackage: false, smdId: 0 },
    ],
    availableQty: "",
    price: 0,
    mdId: 0,
    image_link: "",
    menuId: "",
    status: null,
    isIncludedInPackage: null,
    quantity: 0,
  });
  const [isUpdateError, setUpdateError] = useState(false);
  useEffect(() => {
    setMenuAction(true);
    getMenuListAction();
  }, []);

  useEffect(() => {
    if (isMenuUpdatedSuccess) {
      presentToast("top", "Menu updated successfully.");
      getMenuListAction();
      setIsOpen(false);
      resetUpdateAction();
    }
  }, [isMenuUpdatedSuccess]);

  useEffect(() => {}, [selectedMenu]);

  useEffect(() => {
    if (isSubMenuSuccess) {
    }
  }, [isSubMenuSuccess]);

  const handleSearch = (txt: any) => {
    setSearchText(txt);
  };

  const handleMenuEdit = (selectedMenu: any) => {
    setIsOpen(true);
    setSelectedMenu(selectedMenu);

    // setSubSelectedMenu(selectedMenu.subMenuDetails);
  };

  const handleToogle = (e: any, updatedSubMenu: any) => {
    let updatedSubCategoryData = {
      subCategoryName: updatedSubMenu.subCategoryName,
      price: updatedSubMenu.price,
      availableQty: updatedSubMenu.availableQty,
      discount: updatedSubMenu.discount,
      type: updatedSubMenu.type,
      status: e.target.checked,
      description: updatedSubMenu.description,
      image: updatedSubMenu.image,
      cuisineType: updatedSubMenu.cuisineType,
      subMenuId: updatedSubMenu.subMenuId,
      menuId: selectedMenu.menuId,
    };
    // updateSubMenuAction(updatedSubCategoryData);
  };

  const showErrMessage = (msg: any) => {
    setUpdateError(true);
    presentToast("top", msg);
  };

  const handleMenuUpdate = (e: any, smdId: Number) => {
    setUpdateError(false);
    if (e.target.name == "status" || e.target.name == "isIncludedInPackage") {
      setSelectedMenu((prevValue) => ({
        ...prevValue,
        [e.target.name]: e.target.checked == true ? 1 : 0,
      }));
    } else if (e.target.name.includes("submenu_")) {
      //setting subMenu
      let currentSubMenu = selectedMenu.subMenuDetails;
      currentSubMenu.forEach((element: any) => {
        if (smdId == element.smdId) {
          if (e.target.name.includes("submenu_status")) {
            element.status = e.target.checked == true ? 1 : 0;
          } else if (e.target.name.includes("submenu_isIncludedInPackage")) {
            element.isIncludedInPackage = e.target.checked == true ? 1 : 0;
          } else if (e.target.name.includes("submenu_availableQty")) {
            element.availableQty =
              e.target.value <= 0 || isNaN(e.target.value)
                ? showErrMessage("Quantiy should be a numeric value")
                : parseInt(e.target.value);
          }
        }
      });

      setSelectedMenu((prevValue) => ({
        ...prevValue,
        subMenuDetails: currentSubMenu,
      }));
    } else {
      setSelectedMenu((prevValue) => ({
        ...prevValue,
        [e.target.name]:
          // e.target.name == "price" ? parseInt(e.target.value) : e.target.value,
          e.target.name == "availableQty"
            ? e.target.value <= 0 || isNaN(e.target.value)
              ? showErrMessage("Quantiy should be a numeric value")
              : parseInt(e.target.value)
            : e.target.value,
      }));
    }
  };

  const updateMenu = () => {
    updateMenuAction(selectedMenu);
  };

  return (
    <IonPage>
      <AuthHeader></AuthHeader>
      <IonContent className="ion-padding">
        <>
          <div className="listing-block">
            <IonLabel className="pagename">
              <h2>Edit Menus</h2>
            </IonLabel>

            <IonCard className="table-layout">
              <IonCardContent>
                {menuData.length > 0 && (
                  <IonRow>
                    <IonCol size="12" size-md="12">
                      <div className="table-wrapper">
                        <table>
                          <tr>
                            <th>Menu ID</th>
                            <th>Menu Image</th>
                            <th>Menu Name</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Status</th>
                            <th>Included in Package</th>
                            <th>Edit</th>
                          </tr>
                          {menuData.map((menu, index) => {
                            return (
                              <>
                                <tr>
                                  <td>{menu.mdId}</td>
                                  <td>
                                    {" "}
                                    <img
                                      alt=""
                                      width={50}
                                      height={50}
                                      src={
                                        menu.image_link
                                          ? menu.image_link
                                          : "assets/img/menu/default.png"
                                      }
                                    />
                                  </td>
                                  <td>{menu.menuName}</td>
                                  <td>{menu.price}</td>
                                  <td>{menu.availableQty}</td>
                                  <th>
                                    {menu.status == 1 ? "Active" : "Inactive"}
                                  </th>
                                  <th>
                                    {menu.isIncludedInPackage == 1
                                      ? "Yes"
                                      : "No"}
                                  </th>
                                  <td>
                                    <IonButton
                                      onClick={() => handleMenuEdit(menu)}
                                    >
                                      <IonIcon src={pencilOutline} />
                                    </IonButton>{" "}
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </table>
                      </div>
                    </IonCol>
                  </IonRow>
                )}

                {menuData.length == 0 && <div>Menu data Is Empty...</div>}
              </IonCardContent>
            </IonCard>

            <IonModal
              isOpen={isOpen}
              backdropDismiss={false}
              className="large-modal-custom"
            >
              <IonContent className="ion-padding">
                <IonList>
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <h4>Edit Menu details</h4>
                      </IonCol>
                    </IonRow>

                    <IonRow>
                      <IonCol size="9" size-md="9">
                        <IonItem fill="outline">
                          <IonLabel position="floating">Menu name</IonLabel>
                          <IonInput
                            id="menuName"
                            name="menuName"
                            type="text"
                            // onIonChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            value={selectedMenu.menuName}
                            spellCheck={false}
                            autocapitalize="off"
                            required
                            maxlength={60}
                            disabled
                          ></IonInput>
                        </IonItem>
                      </IonCol>

                      <IonCol size="3" size-md="3">
                        <IonItem fill="outline">
                          <IonLabel position="floating">Quantity</IonLabel>
                          <IonInput
                            name="availableQty"
                            type="number"
                            min={1}
                            value={selectedMenu.availableQty}
                            onIonChange={(e) => handleMenuUpdate(e, 0)}
                            pattern="[1-9]"
                          ></IonInput>
                        </IonItem>
                      </IonCol>
                    </IonRow>

                    <IonRow>
                      <IonCol size="4" size-md="4">
                        <IonItem fill="outline">
                          <IonLabel position="floating">Price</IonLabel>
                          <IonInput
                            name="price"
                            type="number"
                            min={1}
                            value={selectedMenu.price}
                            onIonChange={(e) => handleMenuUpdate(e, 0)}
                            pattern="[1-9]"
                            disabled
                          ></IonInput>
                        </IonItem>
                      </IonCol>
                      <IonCol size="4" size-md="4">
                        <IonItem>
                          <IonLabel>Status</IonLabel>
                          <IonToggle
                            name="status"
                            slot="end"
                            checked={selectedMenu.status ? true : false}
                            onIonChange={(e) => handleMenuUpdate(e, 0)}
                            // onIonChange={(e) => {
                            //   handleToogle(e, subMenu);
                            // }}
                          ></IonToggle>
                        </IonItem>
                      </IonCol>
                      <IonCol size="4" size-md="4">
                        <IonItem>
                          <IonLabel>Included in package</IonLabel>
                          <IonToggle
                            name="isIncludedInPackage"
                            slot="end"
                            checked={
                              selectedMenu.isIncludedInPackage ? true : false
                            }
                            onIonChange={(e) => handleMenuUpdate(e, 0)}
                            // onIonChange={(e) => {
                            //   handleToogle(e, subMenu);
                            // }}
                          ></IonToggle>
                        </IonItem>
                      </IonCol>
                    </IonRow>
                  </IonGrid>

                  <h4>SubMenu</h4>
                  <IonGrid>
                    <IonRow>
                      <IonCol size="4" size-md="4">
                        <IonItem fill="outline">
                          <IonLabel position="floating">Search</IonLabel>
                          <IonInput
                            name="searchMenu"
                            type="text"
                            onIonChange={(e) => handleSearch(e.target.value)}
                            value={searchText}
                            // onIonChange={(e) => handleMenuUpdate(e, 0)}
                            // pattern="[1-9]"
                            // disabled
                          ></IonInput>
                        </IonItem>
                      </IonCol>
                    </IonRow>
                  </IonGrid>

                  <>
                    <table>
                      <tr>
                        <th>Name</th>
                        <th>Qty</th>
                        <th>Price</th>
                        <th>Available Individually</th>
                        <th>Included in pack</th>
                      </tr>
                      {selectedMenu.subMenuDetails
                        .sort((a: any, b: any) => a.smdId - b.smdId)
                        .filter((data) =>
                          data.subCategoryName
                            .toLowerCase()
                            .includes(searchText?.toLowerCase().trim())
                        )
                        .map((subMenu: any) => {
                          return (
                            <tr>
                              <td>{subMenu.subCategoryName}</td>
                              <td>
                                {" "}
                                <IonInput
                                  name={"submenu_availableQty"}
                                  type="number"
                                  pattern="[1-9]"
                                  min={1}
                                  value={subMenu.availableQty}
                                  onIonChange={(e) =>
                                    handleMenuUpdate(e, subMenu.smdId)
                                  }
                                ></IonInput>
                              </td>
                              <td>
                                {" "}
                                <IonInput
                                  name={"submenu_price"}
                                  type="number"
                                  min={1}
                                  pattern="[1-9]"
                                  value={subMenu.price}
                                  onIonChange={(e) =>
                                    handleMenuUpdate(e, subMenu.smdId)
                                  }
                                  disabled
                                ></IonInput>
                              </td>

                              <td>
                                {" "}
                                <IonToggle
                                  name={"submenu_status"}
                                  slot="end"
                                  checked={subMenu.status ? true : false}
                                  onIonChange={(e) =>
                                    handleMenuUpdate(e, subMenu.smdId)
                                  }

                                  // onIonChange={(e) => {
                                  //   handleToogle(e, subMenu);
                                  // }}
                                ></IonToggle>
                              </td>
                              <td>
                                {" "}
                                <IonToggle
                                  name={"submenu_isIncludedInPackage"}
                                  slot="end"
                                  checked={
                                    subMenu.isIncludedInPackage ? true : false
                                  }
                                  onIonChange={(e) =>
                                    handleMenuUpdate(e, subMenu.smdId)
                                  }

                                  // onIonChange={(e) => {
                                  //   handleToogle(e, subMenu);
                                  // }}
                                ></IonToggle>
                              </td>
                            </tr>
                          );
                        })}
                    </table>
                  </>
                </IonList>

                <IonRow>
                  <IonCol>
                    <IonButton
                      type="button"
                      onClick={() => updateMenu()}
                      disabled={isUpdateError}
                    >
                      Update
                    </IonButton>
                    <IonButton type="button" onClick={() => setIsOpen(false)}>
                      Cancel
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonContent>
            </IonModal>
          </div>
        </>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  menuData: state.orders.menuData,
  isMenuSuccess: state.orders.isMenuSuccess,
  isMenuError: state.orders.isMenuError,
  isSubMenuSuccess: state.orders.isSubMenuSuccess,
  isSubMenuError: state.orders.isSubMenuError,
  isMenuUpdatedSuccess: state.orders.isMenuUpdatedSuccess,
  isMenuUpdatedError: state.orders.isMenuUpdatedError,
});

function mapDispatchToProps(dispatch: any) {
  return {
    getMenuListAction: () =>
      dispatch({
        type: ACTION_TYPES.GET_MENU_REQUEST,
      }),
    setMenuAction: (menuEnabled: boolean) =>
      dispatch({
        type: DATA_ACTION_TYPES.SET_MENU_ENABLED,
        payload: menuEnabled,
      }),
    updateMenuAction: (subMenu: boolean) =>
      dispatch({
        type: ACTION_TYPES.UPDATE_MENU_REQUEST,
        payload: subMenu,
      }),
    resetUpdateAction: () =>
      dispatch({
        type: ACTION_TYPES.RESET_UPDATES_REQUEST,
      }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditMenu);
